import React, {Component} from 'react';
import { Button, Divider, Grid, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import '../App.css';

import ConstValues from '../utils/ConstValues';

export default class FinalQuiz extends Component{

    constructor(){
        super();
        
        this.exit = this.exit.bind(this);
        this.goWeb = this.goWeb.bind(this);
    }

    componentDidMount(){
    }

    exit() {
        window.open('https://www.google.com/', '_self');
        // customWindow.close();
    }

    goWeb() {
        window.open('https://www.grupotoledo.ec/',"_self");
    }

    render(){
        return(
            <div className="container-principal">
                <div className="title-size">Gracias por compartirnos tu historia</div>
                <div style={{marginTop: 30, marginBottom: 20}}>
                    <Button variant="contained" size='large' className="button-contained" 
                        onClick={this.exit()}
                    >
                        Finalizar
                    </Button>
                </div>
            </div>
        )
    }
}