// const firebaseConfig = {
//   apiKey: "AIzaSyDetyvUxXXZYHctCSiaKjGfF8QmlPnfjcc",
//   authDomain: "prueba-gt-25a89.firebaseapp.com",
//   projectId: "prueba-gt-25a89",
//   storageBucket: "prueba-gt-25a89.appspot.com",
//   messagingSenderId: "359705996221",
//   appId: "1:359705996221:web:51df8d72c81621c75780d4"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCO7w-BLTbyyDGDHWTEXdGmax7YpR8iyZM",
  authDomain: "grupo-toledo.firebaseapp.com",
  projectId: "grupo-toledo",
  storageBucket: "grupo-toledo.appspot.com",
  messagingSenderId: "703689492900",
  appId: "1:703689492900:web:22660e749ea0d729bed931",
  measurementId: "G-5C4TDG5XTS"
};


export default firebaseConfig;