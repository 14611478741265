import React from 'react';
import './App.css';
import Logged from './navigation/Logged';
import Guest from './navigation/Guest';
import Preloader from './components/Preloader';

import firebaseConfig from './utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default class App extends React.Component {

  constructor(){
    super();
    this.state = {
      loader: false,
      isLogged: false
    }
  }

  componentDidMount(){
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        this.setState({isLogged: true, loader: true});
      } else {
        this.setState({ isLogged: false, loader: true });
      }



    })
  }

  render(){
    const {loader, isLogged} = this.state;
    if(!loader){
      return (<Preloader/>);
    }else{
      if(isLogged){
        return (<Logged/>);
      }else{
        return (<Guest/>);
      }
    }
  }
}