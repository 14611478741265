import React, {Component} from 'react';
import { Button } from '@mui/material';
import '../App.css';

import ConstValues from '../utils/ConstValues';
import Preloader from '../components/Preloader';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Wellcome extends Component{

    constructor(){
        super();
        this.state = {
            evaluation: '',
            activeClarification: false,
            withData: false,
            loader: false 
        }
        this.goQuiz = this.goQuiz.bind(this);
    }

    componentDidMount(){
        console.log('name', this.props.params.name_id);
        if(this.props.params.name_id){
            let array_evaluation = this.props.params.name_id.split('-');
            console.log('array', array_evaluation);
            this.getEvaluation(array_evaluation[1]);
        }
    }

    getEvaluation(id){
        fetch(ConstValues.SERVICE_CONNECT+'evaluation/'+id, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data['status'] === 1){
                let evaluation = data['text'];
                console.log(evaluation);
                this.setState({evaluation});
                this.getClarifications(evaluation.ci);
                
            }
        })
        .catch(console.error)
    }

    getClarifications(identification){
        firebase.firestore()
        .collection('control_organisms')
        .doc(identification)
        .get().then(res => {
            console.log(res.data());
            if(res.data()){
                this.setState({withData: true});
                let control_organisms = res.data();
                let dataActor = control_organisms.judicial_function.actor.data;
                let dataDefendant = control_organisms.judicial_function.defendant.data;
                let countClarification = 0;
                dataActor.forEach(item => {
                    if(item.clarification && !item.clarification_result){
                        countClarification++;
                    }
                });
    
                dataDefendant.forEach(item => {
                    if(item.clarification && !item.clarification_result){
                        countClarification++;
                    }
                });
                console.log(countClarification);
                if(countClarification > 0){
                    this.setState({activeClarification: true})
                }
            }else{
                this.setState({withData: false});
            }
            this.setState({loader: true})
        })
    }
    
    goQuiz() {
        this.props.navigate('/quiz/'+this.state.evaluation.id);
    }

    render(){
        if(this.state.loader){
            if(this.state.evaluation){
                // let image = ConstValues.IMAGE_COMPANY+this.state.evaluation.id_empresa+'.jpg';
                if(this.state.withData){
                    if(this.state.activeClarification){
                        return(
                            <div className="container-principal">
                                <div className="title-size">Bienvenido al portal de Candidatos de Grupo Toledo</div>
                                <div style={{marginTop: 30, marginBottom: 20}}>
                                    <Button variant="contained" size='large' className="button-contained" 
                                        onClick={this.goQuiz}
                                    >
                                        Comenzar
                                    </Button>
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div className='container-final' style={{textAlign: 'center'}}>
                                <div className="title-size">SU INFORMACION YA HA SIDO INGRESADA.</div>
                            </div>
                        )
                    }
                }else{
                    return (
                        <div className='container-final' style={{textAlign: 'center'}}>
                            <div className="title-size">EVALUACION SIN REGISTROS.</div>
                        </div>
                    )
                }
            }
        }else{
            return (<Preloader/>);
        }
    }
}
