import React, {Component} from 'react';
import { TextField, Button, Grid, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import axios from 'axios';
import '../App.css';

import Preloader from '../components/Preloader';
import ConstValues from '../utils/ConstValues';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Quiz extends Component{

    constructor(){
        super();
        this.state={
            visible: [true, false, false, false, false, false, false],
            evaluation: '',
            judicial_function: [],
            data_judicial_function: '',
            isClarification: false,
            clarification_result: '',
            blockQuestion: true,
            fileUser: '',
            fileUserComplete: '',
            progressUpload: 0,
            textButtonUpload: 'Adjuntar documento',
            disabledUpload: false,
        }
        this.count_questions = 0;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        
        this.getEvaluation(this.props.params.id);
    }

    getEvaluation(id){
        fetch(ConstValues.SERVICE_CONNECT+'evaluation/'+id, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data['status'] === 1){
                let evaluation = data['text'];
                this.setState({evaluation});
                this.getClarifications(evaluation.ci);
            }
        })
        .catch(console.error)
    }

    getClarifications(identification){
        firebase.firestore()
        .collection('control_organisms')
        .doc(identification)
        .get().then(res => {
            let control_organisms = res.data();
            let dataActor = control_organisms.judicial_function.actor.data;
            let dataDefendant = control_organisms.judicial_function.defendant.data;
            let data_judicial_function = {...control_organisms.judicial_function};
            let countClarification = 0;
            let judicial_function = [];
            dataActor.forEach((item, indexA) => {
                if(item.clarification && !item.clarification_result){
                    countClarification++;
                    item.indexItem = indexA;
                    item.origin = 'actor';
                    item.clarification_result = '';
                    item.image = '';
                    judicial_function.push(item);
                }
            });

            dataDefendant.forEach((item, indexD) => {
                if(item.clarification && !item.clarification_result){
                    countClarification++;
                    item.indexItem = indexD;
                    item.origin = 'defendant';
                    item.clarification_result = '';
                    item.image = '';
                    judicial_function.push(item);
                }
            });

            if(countClarification > 0){
                console.log(judicial_function)
                this.setState({
                    loader: true, 
                    judicial_function,
                    data_judicial_function,
                    isClarification: true
                })
            }else{
                this.setState({
                    loader: true, 
                    judicial_function: [],
                    data_judicial_function: '',
                    isClarification: false
                })
            }
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let judicial_function = this.state.judicial_function;
        judicial_function[this.count_questions][name] = value;
        this.setState({judicial_function});
    }

    handleImageChange = (event) => {
        let files = event.target.files;
        const formData = new FormData();  
        formData.append("File", files[0]);

        let judicial_function = this.state.judicial_function;
        let item = judicial_function[this.count_questions];
        let name = this.state.evaluation.ci+'-clarificacion-'+item.origin+item.indexItem;

        axios.post(
            ConstValues.SERVICE_CONNECT+'evaluation/save_file/'+name, 
            formData,
            {
                onUploadProgress: (progressEvent) => {
                    let fileLoaded = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    let fileTotal = Math.floor(progressEvent.total / 1000);
                    let fileSize;
                    (fileTotal < 1024) ? fileSize = fileTotal + " KB" : fileSize = (progressEvent.loaded / (1024*1024)).toFixed(2) + " MB";
                    if(progressEvent.loaded == progressEvent.total){
                        this.setState({
                            textButtonUpload: 'finalizada',
                            progressUpload: 0,
                            disabledUpload: true
                        })
                    }else{
                        this.setState({
                            textButtonUpload: 'Cargardo: '+fileLoaded+', Archivo:'+fileSize,
                            progressUpload: fileLoaded,
                            disabledUpload: true
                        })
                    }
                    console.log(fileLoaded, fileTotal, fileSize)
                }
            }
        )
        .then(res => {
            console.log(res);
            if(res.data.status == 1){
                judicial_function[this.count_questions].image = res.data.text;
                let nameFile = files.item(0).name
                if(nameFile.length > 20){
                    nameFile = nameFile.substring(0 , 18)+'...'
                } 

                this.setState({
                    judicial_function, 
                    fileUser: nameFile,
                    fileUserComplete: files.item(0).name
                });
                toast.info('Adjunto subido correctamente');
            }else{
                toast.error(res.data.text);
            }
        })
        .catch(error => {
            console.error(error);
        })
    }

    updateProgressBarValue(progress){
        console.log(progress);
    }

    deleteFile = () => () => {
        let judicial_function = this.state.judicial_function;
        let data = {name: judicial_function[this.count_questions].image}
        axios.post(ConstValues.SERVICE_CONNECT+'evaluation/delete_file', data)
        .then(res => {
            console.log(res);
            if(res.data.status == 1){
                judicial_function[this.count_questions].image = '';
                this.setState({
                    judicial_function, 
                    fileUser: '',
                    fileUserComplete: '',
                    disabledUpload: false,
                    textButtonUpload: 'Adjuntar documento'
                });
                toast.info(res.data.text);
            }else{
                toast.error(res.data.text);
            }
        })
        .catch(error => {
            console.error(error);
        })
    }

    saveContinue = () => () => {
        console.log('save',this.count_questions)
        let lastItemJudicial = this.state.judicial_function.length - 1
        this.setState({blockQuestion: false});
        if(this.count_questions == lastItemJudicial){
            console.log('quiz 1', this.state.judicial_function);
            console.log('quiz 2', this.state.data_judicial_function);
            this.saveSectionFirebase(this.state.data_judicial_function);
        }else{
            this.setState({fileUser: '', fileUserComplete: '', textButtonUpload: 'Adjuntar documento'});
            this.count_questions++;
        }
    }

    saveSectionFirebase(dataUpdate){
        let data = {judicial_function: dataUpdate};
        firebase.firestore()
        .collection('control_organisms')
        .doc(this.state.evaluation.ci)
        .update(data).finally(
            () => {
                this.props.navigate('/final');
            }
        );
    }

    render(){
        if(this.state.loader){
            if(this.state.isClarification){
                return(
                    <div>
                        <div className="container-questions">
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>
                                    Proporcione información adicional a
                                    {' '+this.state.evaluation.nombre_empresa}
                                </div>
                                <div className='subtitle-section'>
                                    Agregue información que ayude a entender el contexto de esta observación.
                                </div>

                                <div className='subtitle-section'>
                                    <div style={{fontWeight: 'bold'}}>Fecha: {this.state.judicial_function[this.count_questions].date_admission}</div>
                                    <div style={{fontWeight: 'bold'}}>{this.state.judicial_function[this.count_questions].action_violation}</div>
                                </div>
                                <div style={{marginBottom: 20}}>
                                    <TextField 
                                        name="clarification_result" 
                                        label=''
                                        value={this.state.judicial_function[this.count_questions].clarification_result} 
                                        onChange={(event) => this.handleInputChange(event)}
                                        margin="normal"
                                        multiline
                                        rows={5}
                                        fullWidth
                                        helperText={`${this.state.judicial_function[this.count_questions].clarification_result.length}/300`}
                                        FormHelperTextProps={{style: {textAlign: 'right'}}}
                                        inputProps={{maxLength: 300}}
                                    />
                                </div>
                                <div style={{marginBottom: 20}}>
                                    <div className='subtitle-section'>
                                        Adjuntar documento:
                                    </div>
                                    <label htmlFor="contained-button-file" style={{width: '100%'}}>
                                        <input id="contained-button-file" type="file" hidden 
                                            onClick={e => (e.target.value = null)}
                                            onChange={(event) => this.handleImageChange(event)} 
                                            disabled={this.state.disabledUpload}
                                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                                        />
                                        <div style={{position: 'relative', display: 'block'}}>
                                            <progress max={100} value={this.state.progressUpload}></progress>
                                            <div className="button-outlined" aria-label="upload picture" component="span">
                                                {this.state.textButtonUpload == 'finalizada'? 
                                                (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={10} md={10} sm={10} style={{display: 'flex',alignItems: 'center'}}>
                                                            <Tooltip title={this.state.fileUserComplete} placement="top-start" enterTouchDelay={0}>
                                                                <div style={{marginLeft: 10}}>
                                                                    {this.state.fileUser}
                                                                </div>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={2} md={2} sm={2} style={{textAlign: 'right'}}>
                                                            <Tooltip title="Eliminar archivo" placement="left">
                                                                <IconButton style={{ color: 'red' }} onClick={this.deleteFile()}>
                                                                    <CloseIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                ):
                                                (<span>{this.state.textButtonUpload}</span>)
                                                }
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div style={{marginBottom: 20}}>
                                    <Button variant="contained" size='large' onClick={this.saveContinue()} className="button-contained">
                                        Continuar
                                    </Button>
                                </div>
                            </div>
                        </div> 
                    </div>
                )
            }else{
                return (
                    <div>
                        <div className="container-questions">
                            <div className="title-size">Gracias por compartirnos tus historia</div>
                            <div className="title-size">Ya no tiene aclarciones que agregar</div>
                        </div>
                    </div>
                )
            }
        }else{
            return (<Preloader/>)
        }
    }
}
